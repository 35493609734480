* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.pic img {
  width: 100%;
}

body,
html{
  scrollbar-width:thin;
  scrollbar-color: #daa520 hsl(0, 0%, 0%);
}

.pic .text {
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  position: absolute;
  top: 55%; 
  left: 45%;
  transform: translate(-50%, -50%); 
  max-width: 95%;
  font-size: 35px;
}

#text{
  display: none;
}

.png4{
  position:absolute; 
  left: 55.3%; 
  width: 60px;
  transform: translate(-220%, -400%); 
}
 
.nkar{
  max-width: 100%;
}

.flexal{
  display: flex;
}

.nkar1{
  width: 650px;
  border-radius: 15px;
  margin-left: 20px;
  margin-top: 65px;
}
.mobail{
  display: none;
}

@media all and (max-width: 1050px) {
  .abutn1{
    display: none;
  }
  .abut1{
    display: none;
  }
  .mobail{
    display: block;
  }

}

.about-us{
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 245px;
  margin-bottom: 10px;
  margin-top: 60px;
}

.text12{
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 13px;
  font-size: 20px;
  padding: 15px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.949);
}

.flexal1{
  display: flex;
  margin-top: 20px;
}

.nkar2{
  width: 650px;
  margin-right: 20px;
  border-radius: 15px;
  justify-content: space-between;
}

.our-vision{
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 230px;
  margin-bottom: 5px;
  margin-top: 25px;
}

.text123{
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 15x;
  font-size: 20px;
  padding: 20px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.949);
}
.footer{
  margin-top: 25px;
}

@media all and (max-width: 1700px) {
  .pic .text {
    color: goldenrod;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    position: absolute;
    top: 64%; 
    left: 43.5%;
    transform: translate(-50%, -50%); 
    max-width: 95%;
    font-size: 22px;
  }
  
  #text{
    display: none;
  }
  
  .png4{
    position:absolute; 
    left: 56.1%; 
    width: 50px;
    transform: translate(-220%, -300%); 
  }
   
}


@media all and (max-width: 1050px) {

.pic img {
  max-width: 100%;
  margin-top: 50px;
}

.png4{
  display: none;
}

.pic .text {
  color: goldenrod;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  margin-right: 35px;
  display: none;
}

#text{
  display: block;
  text-align: center;
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  padding: 4px;
}

.flexal{
  display:block;
}
.flexal1{
  display: block;
}
.nkar1{
    max-width: 80%;
    border-radius: 15px;
    margin-left: 47px;
}
.about-us{
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 60px;
}

    
.text12{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  align-items: center;
  text-align: center;
  line-height: 13px;
}

.nkar2{
  max-width: 80%;
  border-radius: 15px;
  margin-left: 47px;
}

.our-vision{
  color: goldenrod;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 60px;
}

.text123{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  align-items: center;
  text-align: center;
  line-height: 13px;
}

}



















