.phonC{
  font-size: 20px;
  color:  goldenrod;
}

.disabled {
  background-color:rgba(255, 0, 0, 0.252) !important; 
  pointer-events: none; 
  cursor: not-allowed; 
}


.emailC{
  font-size: 20px;
  color:  goldenrod;
}

.error{
  margin-left: 5px;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.contacth2{
  font-size: 25px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  text-align: center;
  margin-bottom: 25px;
}

.contactlabel{
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
}

.contactlabel span{
  color: red;
  font-size: 25px;
}
.form-field {
  text-transform:capitalize;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 7px;
}
.containe {
  width: 1000px;
  margin: 90px auto;
  color: rgba(255, 255, 255, 0.863);
  line-height: 1.5;
  border-radius: 8px;
}

.contactUS{
  border-radius: 20px;
}

.text-center {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 25px;
  color:  goldenrod;
}

.content {
  display: flex;
  margin-top: 40px;
}

.icon {
  background-color: white;
  border-radius: 30px;
  padding: 15px;
  margin-top: 15px;
}

.contact-info {
  display: inline-block;
  padding: 4px 20px 0px 20px;
}

.address-line {
  margin-top: 40px;
}

.col-1 {
  width: 500px;
}

.col-2 {
  flex: 1 1 auto;  
  border-radius: 18px;
}

.form-container {
  color: #000;
  padding: 50px;
  border-radius: 15px;
}

.contact-info-title {
  color: var(--first-color);
  font-size: 20px;
}
.contact-info iframe{
  width: 450px; 
  height: 450px;
  border-radius: 20px;
}

.form-row {
  padding-bottom: 20px;
}
.map{
  margin-top: 8px;
}
.map a{
  color: goldenrod;
  font-size: 18px;
}
.map span{
  font-size: 25px;
  padding: 6px;
}

.form-field {
  width: 100%;
  border: none;
  height: 30px;
  border-bottom: 1px solid #000000b4;
  border-radius: 10px;
}

.send-btn {
  border: 0px;
  padding: 12px 26px;
  background-color: var(--first-color);
  color: black;
  border-radius: 13px;
  cursor: pointer;
}
.error-border {
  border: 2px solid red; 
}

@media all and (max-width: 990px) {
  .text-center{
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
  }

  .containe {
    width: 370px;
    margin-top: 80px;
    color: rgba(255, 255, 255, 0.863);
    line-height: 1.5;
    border-radius: 8px;
  }

  .content {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
  }
  
  .icon {
    background-color: white;
    border-radius: 30px;
    padding: 15px;
    vertical-align: top;
  }
  
  .contact-info {
    display: inline-block;
    padding: 4px 20px 0px 20px;
  }

  #nkarmap img{
  max-width: 200px;
  }

  .contact-info iframe{
    width: 100%;
  }
}
