* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.text{
    color: goldenrod;
    font-size: 40px;
    margin-top: 20px;
    padding: 20px;
    margin-left: 80px;
    text-align: center;
    justify-content: center;
    max-width: 90%;
    font-family: Arial, Helvetica, sans-serif;

}

.papersP{
    position: relative;
    padding: 1.8em;
    margin: 60px auto;
    background-color: #f2fffd13;
    border-radius: 15px;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.flex1{
    display: flex;
    flex-wrap: wrap;
    width: 330px;
}

.flex2{
    display: flex;
    flex-wrap: wrap;
    width: 330px; 
}

.text1PROJ{
    color: goldenrod;
    text-align: center;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
}

.text1pp{
    color: white;
    margin-top: 80px;
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 28px;
}

.text2{
    color: goldenrod;
    word-wrap: break-word;
    text-align: center;
    position:relative;
    padding:20px 40px;
    font-size:20px;
    font-family: Arial, Helvetica, sans-serif;
}

.text2pp{
    color: white;
    margin-top: 32px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 28px;
    font-size:18px;
}

.flex3{
    display: flex;
    flex-wrap: wrap;
    width: 330px;   
}

.text3{
    color:  goldenrod;
    word-wrap: break-word;
    text-align: center;
    position:relative;
    padding:20px 40px;
    font-size:20px;
    font-family: Arial, Helvetica, sans-serif;
}

.text3pp{
    color: white;
    margin-top: 27px;
    /* text-align: center; */
    font-size:18px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 28px;
}

.flex4{
    display: flex;
    flex-wrap: wrap;
    width: 330px;
}

.text4{
    color:  goldenrod;
    word-wrap: break-word;
    text-align: center;
    position:relative;
    padding:20px 40px;
    font-size:20px;
    font-family: Arial, Helvetica, sans-serif;
}

.text4pp{
    color: white;
    margin-top: 65px;
    line-height: 28px;
    font-size:18px;    
    font-family: Arial, Helvetica, sans-serif;
}

.flex5{
    display: flex;
    flex-wrap: wrap;
    max-width: 330px;
}

.text5{
    color:  goldenrod;
    word-wrap: break-word;
    text-align: center;
    position:relative;
    padding:20px 40px;
    font-size:20px;
    font-family: Arial, Helvetica, sans-serif;
}

.text5p{
    color: white;
    margin-top: 70px;
    line-height: 28px;
    font-size:18px;
    font-family: Arial, Helvetica, sans-serif;

}

.text1p,
.text2p,
.text3p,
.text4p,
.text5{
    font-family: Arial, Helvetica, sans-serif;
}
 
#Index{
    font-size: 2rem;
    font-weight: bold;
    margin-top: 120px;
}

.read,
.read1,
    .read2
    {
        color:goldenrod;
    }


.pop{
    text-decoration: none;
    display: inline-block;
    width: 200px;
    padding: 1rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 98%;
    transform: translate(-50%, -50%);   
}

#popup h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
}
#popup p {
    color: black;
    font-size: 25px;
    padding: 40px;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 30px;
}

#popup {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 70vh;
    width: 80%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 180px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
}
  
#popup:target {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all .75s;
}
    
#popup a {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}


#Index1{
    font-size: 2rem;
    font-weight: bold;
    margin-top: 120px;
}

.pop1{
    text-decoration: none;
    display: inline-block;
    width: 200px;
    padding: 1rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 98%;
    transform: translate(-50%, -50%);   
}
#popup1 h2 {
    font-size: 30px;
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
}
#popup1 p {
    color: black;
    font-size: 25px;
    padding: 40px;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 30px
}
#popup1 {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid  rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 80vh;
    width: 80%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 180px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
}
  
#popup1:target {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all .75s;
}
 
#popup1 a {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

#Index2{
    font-size: 2rem;
    font-weight: bold;
    margin-top: 122px;
}

#popup2 h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
}
#popup2 p {
    color: black;
    font-size: 25px;
    padding: 40px;
    font-family: Arial, Helvetica, sans-serif;
    text-indent: 30px
}

.pop2{
    text-decoration: none;
    display: inline-block;
    width: 200px;
    padding: 1rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 98%;
    transform: translate(-50%, -50%);   
}
  
#popup2 {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid  rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 80vh;
    width: 80%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 180px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
}
  
#popup2:target {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: all .75s;
}
  
#popup2 a {
    position: absolute;
    height: 30px;
    width: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

@media all and (max-width: 1050px) {
#popup .pop {
    position: sticky;
    z-index: 9999;
    top: 35px;
    right: 0;
}

#popup1 .pop1 {
    position: sticky;
    z-index: 9999;
    top: 35px;
    right: 0;
    }

    #popup2 .pop2 {
    position: sticky;
    z-index: 9999;
    top: 35px;
    right: 0;
}
}
 
@media all and (max-width: 1050px) {
.text{
    color: goldenrod;
    font-size: 20px;
    margin-top: 30px;
    padding: 20px;
    margin-left: 25px;
    font-family: Arial, Helvetica, sans-serif;
}

#popup {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 90vh;
    width: 90%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
    }
    .pop{
    text-decoration: none;
    display: inline-block;
    width: 220px;
    padding: 2rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 95%;
    transform: translate(-50%, -50%);   
}

#popup h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;

}
#popup p {
    color: black;
    font-size: 25px;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

#popup1 {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 90vh;
    width: 90%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
    }
    .pop1{
    text-decoration: none;
    display: inline-block;
    width: 220px;
    padding: 2rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 95%;
    transform: translate(-50%, -50%);   
}

#popup1 h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;

}
#popup1 p {
    color: black;
    font-size: 25px;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

#popup2 {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 90vh;
    width: 90%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
    }
    .pop2{
    text-decoration: none;
    display: inline-block;
    width: 220px;
    padding: 2rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 95%;
    transform: translate(-50%, -50%);   
}

#popup2 h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;

}
#popup2 p {
    color: black;
    font-size: 25px;
    padding: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
}



   