.services{
    margin-top: 150px;
    font-family: Arial, Helvetica, sans-serif;
    color:  goldenrod;
    font-size: 70px;
    text-align: center;
}
.card img{
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;  
}

.card img:hover{
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);  
}
.card img{
    width: 50px;
    padding: 5px;
    color: #daa520;
}

.card{
    position: relative;
    padding: 1.7em;
    margin: 50px auto;
    background-color: #f2fffd13;
    border-radius: 15px;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.ser{
    display: flex;
    flex-wrap: wrap;
    width: 450px;
}

.p{
    color: goldenrod;  
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: bold;
}

.p1{
    line-height: 25px;
    color: rgba(255, 255, 255, 0.775);
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
}

.animation{
    display: none;
}

@media all and (max-width: 1050px) {
    .services{
        margin-top: 120px;
        font-family: Arial, Helvetica, sans-serif;
        color:  goldenrod;
        font-size: 40px;
        text-align: center;
    }
    .Stext{
        color:  goldenrod;
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;
        padding: 20px;
        text-align: center;
        justify-content: center;
        margin-left: 18px;
    }

    .Stext{
        word-wrap: break-word;
        text-align: center;
        font-size:15px;
    }

    .card{
        position: relative;
        padding: 1.7em;
        margin: 50px auto;
        background-color: #f2fffd13;
        border-radius: 15px;
    }

    .ser{
        display: flex;
        flex-wrap: wrap;
        width: 310px;
    }
    .anime{
        display: none;
    }

    .animation{
        display:block;

        width: 50px;
        padding: 5px; 
        color: #daa520;
    }

    [data-aos="rotate-c"].aos-animate {
        transform: rotate(360deg);
      }
    
}