
.textg {
  margin-top: 120px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  color: goldenrod;
  font-size: 70px;
}

.hay {
  display: flex;
  flex-wrap: wrap;
  margin-left: 110px;
  margin-top: 150px;
  padding: 50px;
}

.vs {
  position: relative;
}

.vss{
  position: relative;

}

.hay > div {
  margin: 15px; 
}

.n1{
  border-radius: 25px;
  width: 300px;
}

.n2{
  border-radius: 25px;
  width: 300px;
}

.n3{
  border-radius: 25px;
  width: 300px;
}

.n4{
  border-radius: 25px;
}

.n5{
  border-radius: 25px;
  width: 300px;
}

@media all and (max-width: 1050px) {
  .textg {
    margin-top: 120px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    color: goldenrod;
    font-size: 50px;
  }

  .hay {
    display: inline;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .hay > div {
    margin: 25px; 
  }

  .n1{
    border-radius: 25px;
    width: 300px;
  }
  
  .n2{
    border-radius: 25px;
    width: 300px;
  }
  
  .n3{
    border-radius: 25px;
    width: 300px;
  }
  
  .n4{
    border-radius: 25px;
    width: 300px;
    margin-top: 15px;
  }
  
  .n5{
    border-radius: 25px;
    width: 300px;
  }
  .vs {
    position: relative;
  }

}