* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --first-color:goldenrod; 
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 75%);
 --body-color: hsl(0, 0%, 6%);
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 background-color: black;
 font-family: Arial, Helvetica, sans-serif;
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: var(--z-fixed);
  background-color: black;
}
 
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  margin: 2rem;
}
.nav__item{
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  padding: 7px ;
  position: relative;
}

li:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: goldenrod ;
  transition: width 0.5s ease 0s, left 0.5s ease 0s;
  width: 0;
}

li:hover:after { 
  width: 100%; 
  left: 0; 
}

/* .active{
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-style: solid;
} */

.active{
  font-size: 32px !important;
}

.nav__logo {
  color: var(--first-color);
  font-family: Arial, Helvetica;
  background-color: black !important; 
  font-size: 30px;
}
 
.nav__toggle,
.nav__close {
  font-size:4rem;
  color: var(--title-color);
  cursor: pointer;
}
 
.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}
 
.nav__link {
  color: var(--title-color);
  font-family: Arial, Helvetica, sans-serif;
  color:var(--first-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  font-size: 20px;
}
 
.nav__link:hover {
  color: var(--first-color);
}
 
.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}
 
.show-menu {
  right: 0;
}

@media screen and (max-width: 1150px) {
.nav__menu {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: hsla(0, 0%, 6%, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 80%;
  height: 100%;
  padding: 6rem 3rem 0;
  transition: right 0.4s;
}

.nav__toggle {
  display: block;
}

.nav__close {
  display: none;
}

.nav__menu.show-menu {
  right: 0;
}

.nav__list {
  flex-direction: column;
  row-gap: 1.5rem;
  margin-top: 3rem;
}
}

@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }

  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}
